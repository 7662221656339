<template>
    <div class="serveBindingManage">
        <ds-header :title="title"></ds-header>
        <div>
            <a-form-model
                    ref="ruleForm"
                    :label-col="labelCol"
                    :model="form"
                    :rules="rules"
                    :wrapper-col="wrapperCol">
                <a-form-model-item ref="name" label="名称" prop="name" :required="true">
                    <a-input
                            v-model="form.name"
                            :disabled="disable || disableName"
                            placeholder="请输入名称"
                            style="width: 300px"
                            @blur="() => {
                $refs.name.onFieldBlur();
              }"/>
                </a-form-model-item>
                <a-form-model-item ref="projectIds" label="适用项目" prop="projectIds" :required="true">
                    <a-select
                            v-model:value="form.projectIds"
                            :disabled="disable"
                            :options="projects"
                            mode="multiple"
                            placeholder="请选择适用项目"
                            style="width: 300px"
                            @change="onProjectChange"
                    ></a-select>
                </a-form-model-item>
                <a-form-model-item ref="positions" label="位置" prop="positions" :required="true">
                    <a-checkbox-group v-model:value="form.positions" :disabled="disable" name="checkboxgroup"
                                      :options="positions"
                                      @change="onPositionChange"/>
                </a-form-model-item>
                <a-form-model-item ref="position" label=" " prop="position">
                    <a-table bordered :data-source="redirctUrlData" :disabled="disable" :columns="redirctUrlColumns"
                             :pagination="false">

                        <span slot="redirctUrlTitle"><span style="color: #f5222d">*</span>&nbsp;跳转链接</span>
                        <template slot="redirctUrl" slot-scope="text, record">
                            <a-input :disabled="disable" v-model="record.redirctUrl"/>
                        </template>
                    </a-table>
                </a-form-model-item>
                <a-form-model-item ref="picUrl" label="挂件形象" prop="picUrl" :required="true">
                    <a-upload
                            :disabled="disable"
                            name="file"
                            list-type="picture-card"
                            :action="IMG_API + '/oss/files'"
                            class="avatar-uploader"
                            :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
                            :file-list="picList"
                            @change="picChangeImg"
                            :before-upload="picBeforeUpload"
                    >
                        <div v-if="picList.length < 1">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">Upload</div>
                        </div>
                    </a-upload>
                    <p class="tip"><span style="margin-right: 5px;color: red">*</span>图片格式为jpg/png类型，尺寸为200*200px，最大不能超过500kb
                    </p>
                </a-form-model-item>
                <a-form-model-item ref="animationUrl" label="挂件开场动画" prop="animationUrl" :required="true">
                    <a-upload
                            :disabled="disable"
                            name="file"
                            list-type="picture-card"
                            :action="IMG_API + '/oss/files'"
                            class="avatar-uploader"
                            :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
                            :file-list="animationList"
                            @change="animationChangeImg"
                            :before-upload="animationBeforeUpload"
                    >
                        <div v-if="animationList.length < 1">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">Upload</div>
                        </div>
                    </a-upload>
                    <p class="tip"><span style="margin-right: 5px;color: red">*</span>格式为gif，尺寸660*200px，最大不能超过1mb
                    </p>
                </a-form-model-item>
                <a-form-model-item ref="animationStatus" label="挂件开场动画状态" prop="animationStatus"
                                   :required="true">
                    <a-radio-group :disabled="disable" name="radioGroup" v-model:value="form.animationStatus"
                                   :default-value="this.form.animationStatus">
                        <a-radio :value="1">
                            启用
                        </a-radio>
                        <a-radio :value="0">
                            禁用
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="form.positions.indexOf(0) !== -1" ref="position" label="首页固定时段气泡配置"
                                   prop="position">
                    <a-button type="primary" :disabled="disable" @click="() => this.visible = true">
                        添加
                    </a-button>
                    <a-table :columns="bubbleColumns" :data-source="bubbleData" bordered :pagination="false">
                        <template slot="bubbleUrlSlot" slot-scope="text, record, index">
                            <img :src="text" style="width: 205px; height: 100px">
                        </template>
                        <template slot="operation" slot-scope="text, record, index">
                            <div class="editable-row-operations">
                                <a-button type="link" :disabled="disable" @click="deleteBubble(record.key)">删除
                                </a-button>
                            </div>
                        </template>
                    </a-table>
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                    <a-button v-show="disable" style="margin-left: 10px;" @click="backList">
                        返回
                    </a-button>
                    <a-button v-show="!disable" type="primary" @click="onSubmit">
                        保存
                    </a-button>
                    <a-button v-if="!disable" style="margin-left: 10px;" @click="backList">
                        取消
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </div>
        <a-modal
                title="编辑"
                :visible="visible"
                @ok="handleBubbleOk"
                :width="800"
                @cancel="handleBubbleCancel"
        >
            <a-form-model
                    ref="bubbleForm"
                    :model="bubbleForm"
                    :rules="bubbleRules"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol"
            >
                <a-form-model-item ref="bubbleUrl" prop="bubbleUrl" label="气泡图片">
                    <a-upload
                            :disabled="disable"
                            name="file"
                            list-type="picture-card"
                            :action="IMG_API + '/oss/files'"
                            class="avatar-uploader"
                            :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
                            :file-list="bubleList"
                            @change="bubleChangeImg"
                            :before-upload="bubbleBeforeUpload"
                    >
                        <div v-if="bubleList.length < 1">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">Upload</div>
                        </div>
                    </a-upload>
                    <p class="tip"><span style="margin-right: 5px;color: red">*</span>图片格式为jpg/png/gif格式，尺寸为410*200px，最大不能超过1mb
                    </p>
                </a-form-model-item>
                <a-form-model-item ref="showStartDate" label="关联开始日期" prop="showStartDate" :required="true">
                    <a-date-picker v-model="bubbleForm.showStartDate"/>
                </a-form-model-item>
                <a-form-model-item ref="showEndDate" label="关联结束日期" prop="showEndDate" :required="true">
                    <a-date-picker v-model="bubbleForm.showEndDate"/>
                </a-form-model-item>
                <a-form-model-item ref="showStartTime" label="关联固定时间段开始时间" prop="showStartTime"
                                   :required="true">
                    <a-time-picker v-model="bubbleForm.showStartTime" format="HH:mm:ss"/>
                </a-form-model-item>
                <a-form-model-item ref="showEndTime" label="关联固定时间段结束时间" prop="showEndTime" :required="true">
                    <a-time-picker v-model="bubbleForm.showEndTime" format="HH:mm:ss"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import {projectName} from '@/api/modularManagement'
import {IMG_API} from "@/config";
import {generateUUID} from "ant-design-vue/lib/vc-select/util";
import {insertDigitalPersonConfig, selectDigitalPersonById, updateDigitalPersonConfig} from "@/api/digitalPerson";
import {selectYearEndActivityById} from "@/api/yearEndActivities";
import {mapState} from "vuex";

export default {
    name: "digitalPersonManageAdd",
    computed: {
        img() {
            return img
        },
      ...mapState({
        projects: (state) => {
          return state.common.projectList && state.common.projectList.length && state.common.projectList.map(item => {
            return {
              label: item.project_name,
              value: item.id
            }
          })
        }
      }),
    },
    data() {
        return {
            title: '新增数字人配置',
            type: 'add',
            visible: false,
            disable: false,
            IMG_API: IMG_API,
            disableName: false,
            labelCol: {span: 8},
            wrapperCol: {span: 16},
            form: {
                id: '',
                name: "",
                projectIds: [],
                positions: [],
                picUrl: "",
                animationUrl: "",
                animationStatus: 1
            },
            bubbleForm: {
                id: '',
                bubbleUrl: "",
                showStartDate: "",
                showEndDate: "",
                showStartTime: "",
                showEndTime: "",
            },
            positions: [{label: '首页', value: 0}, {label: '食住行', value: 1}, {
                label: '我的权益',
                value: 2
            }, {label: '无忧创业', value: 3}, {label: '我的周边', value: 4}],
            redirctUrlColumns: [
                {
                    title: '适用项目',
                    dataIndex: 'projectName',
                    key: 'projectName',
                    width: '30%'
                },
                {
                    title: '位置',
                    dataIndex: 'positionName',
                    key: 'positionName',
                    width: '15%'
                },
                {
                    dataIndex: 'redirctUrl',
                    scopedSlots: {customRender: 'redirctUrl', title: 'redirctUrlTitle'},
                    key: 'redirctUrl',
                },
            ],
            bubbleColumns: [
                {
                    title: '气泡图片',
                    dataIndex: 'bubbleUrl',
                    key: 'bubbleUrl',
                    scopedSlots: {customRender: 'bubbleUrlSlot'},
                },
                {
                    title: '关联日期',
                    dataIndex: 'showDate',
                    key: 'showDate',
                },
                {
                    title: '关联固定时间段',
                    dataIndex: 'showTime',
                    key: 'showTime',
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: {customRender: 'operation'},
                },
            ],
            redirctUrlData: [],
            bubbleData: [],
            tmpProject: [],
            tmpPosition: [],
            picList: [],
            animationList: [],
            bubleList: [],
            rules: {
                name: [
                    {required: true, message: "请输入配置名称", trigger: "change"},
                    {max: 6, message: "配置名称长度不得超过6个字符", trigger: "change"}
                ],
                projectIds: [
                    {required: true, message: "适用项目不能为空", trigger: "change"},
                ],
                positions: [
                    {required: true, message: "展示位置不能为空", trigger: "change"},
                ],
                picUrl: [
                    {required: true, message: "挂件形象不能为空", trigger: "change"},
                ],
                animationUrl: [
                    {required: true, message: "挂件开场动画不能为空", trigger: "change"},
                ],
            },
            bubbleRules: {
                bubbleUrl: [
                    {required: true, message: "气泡图片不能为空", trigger: "change"},
                ],
                showStartDate: [
                    {required: true, message: "关联开始日期不能为空", trigger: "change"},
                ],
                showEndDate: [
                    {required: true, message: "关联结束日期不能为空", trigger: "change"},
                ],
                showStartTime: [
                    {required: true, message: "关联固定时间段开始时间不能为空", trigger: "change"},
                ],
                showEndTime: [
                    {required: true, message: "关联固定时间段结束时间不能为空", trigger: "change"},
                ],
            },
        }
    },
    created() {
        console.log(this.$route.query)
        this.form.id = this.$route.query.id
        if (this.$route.query.type === 'view') {
            this.disable = true
            this.title = '查看数字人配置'
        }
        if (this.$route.query.type === 'edit') {
            this.type = 'edit';
            this.title = '编辑数字人配置'
        }
        if (this.form.id) {
            this.findOne();
        }
    },
    methods: {
        // 提交
        onSubmit() {
            console.log(this.redirctUrlData);
            let urlRegex = /^(http|https|ftp|file|rtmp|rtsp):\/\/([a-zA-Z0-9-_])+\.(.+)+$/;
            for (let i = 0; i < this.redirctUrlData.length; i++) {
                let item = this.redirctUrlData[i];
                if (item.redirctUrl === undefined || item.redirctUrl === null || item.redirctUrl === '') {
                    this.$message.warning('跳转链接不能为空');
                    return;
                } else if (urlRegex.test(item.redirctUrl) === false) {
                    this.$message.warning('跳转链接格式不正确');
                    return;
                }
            }
            for (let i = 0; i < this.redirctUrlData.length; i++) {
                let item = this.redirctUrlData[i];
                if (item.redirctUrl === undefined || item.redirctUrl === null || item.redirctUrl === '') {
                    this.$message.warning('跳转链接不能为空');
                    return;
                } else if (urlRegex.test(item.redirctUrl) === false) {
                    this.$message.warning('跳转链接格式不正确');
                    return;
                }
            }
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    console.log(this.type)
                    const data = {
                        ...this.form,
                        redirctUrls: this.redirctUrlData,
                        bubbles: this.bubbleData
                    };
                    if (this.type === 'edit') {
                        updateDigitalPersonConfig(data).then(res => {
                            if (res.code === "200") {
                                this.$message.success("操作成功！");
                                this.$router.back();
                            } else {
                                this.$message.warning(res.msg);
                            }
                        });
                    } else {
                        insertDigitalPersonConfig(data).then(res => {
                            if (res.code === "200") {
                                this.$message.success("操作成功！");
                                this.$router.back();
                            } else {
                                this.$message.warning(res.msg);
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });

        },
        resetForm() {
            this.$refs.ruleForm.resetFields();
            this.redirctUrlData = [];
        },
        handleBubbleCancel() {
            this.bubleList = [];
            this.$refs.bubbleForm.resetFields();
            this.visible = false;
        },
        handleBubbleOk() {
            console.log(this.bubbleForm);
            this.$refs.bubbleForm.validate(valid => {
                if (valid) {
                    let startTime = moment(this.bubbleForm.showStartDate.format('YYYY-MM-DD') + " " + this.bubbleForm.showStartTime.format('HH:mm:ss'));
                    let endTime = moment(this.bubbleForm.showEndDate.format('YYYY-MM-DD') + " " + this.bubbleForm.showEndTime.format('HH:mm:ss'));
                    if (endTime.isSameOrBefore(startTime) || this.bubbleForm.showEndTime.isSameOrBefore(this.bubbleForm.showStartTime)) {
                        this.$message.warning('结束时间必须大于开始时间');
                        return;
                    }
                    for (let i = 0; i < this.bubbleData.length; i++) {
                        let item = this.bubbleData[i];
                        let itemStartTime = moment(item.showDate.split(' - ')[0] + " " + item.showTime.split(' - ')[0]);
                        let itemEndTime = moment(item.showDate.split(' - ')[1] + " " + item.showTime.split(' - ')[1]);
                        if (this.isPeriodOverlap(itemStartTime, itemEndTime, startTime, endTime)) {
                            this.$message.warning('保存失败，同一天的某一时刻仅可有一条配置数据');
                            return;
                        }
                    }
                    this.bubbleData.push({
                        key: generateUUID(),
                        bubbleUrl: this.bubbleForm.bubbleUrl,
                        showDate: this.bubbleForm.showStartDate.format('YYYY-MM-DD') + ' - ' + this.bubbleForm.showEndDate.format('YYYY-MM-DD'),
                        showTime: this.bubbleForm.showStartTime.format('HH:mm:ss') + ' - ' + this.bubbleForm.showEndTime.format('HH:mm:ss')
                    });
                    this.$refs.bubbleForm.resetFields();
                    this.bubleList = [];
                    this.visible = false;
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        deleteBubble(key) {
            this.bubbleData = this.bubbleData.filter(item => item.key !== key);
        },
        onProjectChange(value) {
            let deleteProject = this.tmpProject.concat(this.form.projectIds).filter(item => !this.form.projectIds.includes(item));
            console.log("deleteProject", deleteProject);
            let addProject = this.tmpProject.concat(this.form.projectIds).filter(item => !this.tmpProject.includes(item));
            console.log("addProject", addProject);
            this.tmpProject = this.form.projectIds;
            // 删除元素
            if (deleteProject.length !== 0) {
                this.redirctUrlData = this.redirctUrlData.filter(item => item.projectId !== deleteProject[0]);
            }
            if (addProject.length !== 0 && this.form.projectIds.length !== 0 && this.form.positions.length !== 0) {
                this.form.positions.forEach(position => {
                    this.redirctUrlData.push({
                        key: addProject[0] + "-" + position,
                        projectId: addProject[0],
                        projectName: this.projects.find(i => i.value === addProject[0]).label,
                        position: position,
                        positionName: this.positions.find(i => i.value === position).label,
                        redirctUrl: ''
                    })
                });
            }
        },
        onPositionChange(value) {
            console.log(this.form.positions.indexOf(0));
            let deletePosition = this.tmpPosition.concat(this.form.positions).filter(item => !this.form.positions.includes(item));
            console.log("deletePosition", deletePosition);
            console.log("是否删除了首页", !(deletePosition.indexOf(0) < 0));
            if (!(deletePosition.indexOf(0) < 0)) {
                this.bubbleData = [];
            }
            let addPosition = this.tmpPosition.concat(this.form.positions).filter(item => !this.tmpPosition.includes(item));
            console.log("addPosition", addPosition);
            this.tmpPosition = this.form.positions;
            // 删除元素
            if (deletePosition.length !== 0) {
                this.redirctUrlData = this.redirctUrlData.filter(item => item.position !== deletePosition[0]);
            }
            if (addPosition.length !== 0 && this.form.projectIds.length !== 0 && this.form.positions.length !== 0) {
                this.form.projectIds.forEach(project => {
                    this.redirctUrlData.push({
                        key: project + "-" + addPosition[0],
                        projectId: project,
                        projectName: this.projects.find(i => i.value === project).label,
                        position: addPosition[0],
                        positionName: this.positions.find(i => i.value === addPosition[0]).label,
                        redirctUrl: ''
                    })
                });
            }
        },
        picBeforeUpload(file) {
            return this.checkBeforeUpload(file, ["jpg", "png"], 500, [200]);
        },
        animationBeforeUpload(file) {
            return this.checkBeforeUpload(file, ["gif"], 1024, [660, 200]);
        },
        bubbleBeforeUpload(file) {
            return this.checkBeforeUpload(file, ["jpg", "png", "gif"], 1024, [410, 200]);
        },
        checkBeforeUpload(file, typeList, size, measurement) {
            let index = file.name.lastIndexOf(".");
            let type = file.name.substring(index + 1, file.name.length);
            const isPic = typeList.includes(type);
            if (!isPic) {
                this.$message.error("上传图片只能是" + typeList.join("、") + "格式!");
                return new Promise(function (resolve, reject) {
                    return reject;
                });
            }
            if (file.size / 1024 > size) {
                if (size >= 1024) {
                    this.$message.error("图片大小不能超过" + size / 1024 + "Mb!");
                } else {
                    this.$message.error("图片大小不能超过" + size + "Kb!");
                }
                return new Promise(function (resolve, reject) {
                    return reject;
                });
            }
            const isSize = new Promise(function (resolve, reject) {
                const URL = window.URL || window.webkitURL;
                const img = new Image();
                img.onload = function () {
                    const valid = img.width === measurement[0] && img.height === (measurement.length === 1 ? measurement[0] : measurement[1]);
                    valid ? resolve() : reject();
                };
                img.src = URL.createObjectURL(file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$message.error("图片不规范，请按要求上传");
                    this.checkSize = false;
                    return reject();
                }
            );
            return isPic && isSize;
        },
        picChangeImg({fileList}) {
            this.picList = fileList;
            if (this.picList.length > 0 && this.picList[0].status === "done") {
                this.form.picUrl = fileList[0].response.redirect_uri;
            }
            if (this.picList.length === 0) {
                this.form.picUrl = "";
            }
        },
        animationChangeImg({fileList}) {
            this.animationList = fileList;
            if (this.animationList.length > 0 && this.animationList[0].status === "done") {
                this.form.animationUrl = fileList[0].response.redirect_uri;
            }
            if (this.animationList.length === 0) {
                this.form.animationUrl = "";
            }
        },
        bubleChangeImg({fileList}) {
            this.bubleList = fileList;
            if (this.bubleList.length > 0 && this.bubleList[0].status === "done") {
                this.bubbleForm.bubbleUrl = fileList[0].response.redirect_uri;
            }
            if (this.bubleList.length === 0) {
                this.bubbleForm.bubbleUrl = "";
            }
        },
        findOne() {
            console.log();
            selectDigitalPersonById(this.form.id).then(res => {
                if (res.code === "200") {
                    console.log(res);
                    const {data} = res;
                    this.form.name = data.name;
                    this.picList = [{
                        uid: "-1",
                        name: "image.png",
                        status: "done",
                        url: res.data.picUrl
                    }];
                    this.form.picUrl = data.picUrl;
                    this.animationList = [{
                        uid: "-1",
                        name: "image.png",
                        status: "done",
                        url: res.data.animationUrl
                    }];
                    this.form.animationUrl = data.animationUrl;
                    this.form.projectIds = data.projectIds;
                    this.tmpProject = data.projectIds;
                    this.form.positions = data.positions;
                    this.tmpPosition = data.positions;
                    this.redirctUrlData = data.redirctUrls;
                    this.bubbleData = data.bubbles;
                    this.form.animationStatus = data.animationStatus;
                }
            });
        },
        backList() {
            this.$router.push({
                path: '/digitalPerson/digitalPersonManage'
            });
        },

        // 才用moment.js判断两个时间段是否有重合
        isPeriodOverlap(firstStartTime, firstEndTime, secondStartTime, secondEndTime) {
            // 获取两个时间段的开始日期和结束日期
            const firstStartDate = moment(firstStartTime).startOf('day');
            const firstEndDate = moment(firstEndTime).startOf('day');
            const secondStartDate = moment(secondStartTime).startOf('day');
            const secondEndDate = moment(secondEndTime).startOf('day');

            // 获取两个时间段中每天的时间段
            const firstDateRanges = [];
            const secondDateRanges = [];

            let currentDate = firstStartDate;
            while (currentDate.isSameOrBefore(firstEndDate)) {
                const currentRange = {
                    start: moment(currentDate).set({ hour: firstStartTime.hours(), minute: firstStartTime.minutes(), second: firstStartTime.seconds() }),
                    end: moment(currentDate).set({ hour: firstEndTime.hours(), minute: firstEndTime.minutes(), second: firstEndTime.seconds() })
                };
                firstDateRanges.push(currentRange);
                currentDate = moment(currentDate).add(1, 'days');
            }

            currentDate = secondStartDate;
            while (currentDate.isSameOrBefore(secondEndDate)) {
                const currentRange = {
                    start: moment(currentDate).set({ hour: secondStartTime.hours(), minute: secondStartTime.minutes(), second: secondStartTime.seconds() }),
                    end: moment(currentDate).set({ hour: secondEndTime.hours(), minute: secondEndTime.minutes(), second: secondEndTime.seconds() })
                };
                secondDateRanges.push(currentRange);
                currentDate = moment(currentDate).add(1, 'days');
            }

            // 判断两个时间段中每天的时间段是否有重合
            let isOverlap = false;
            firstDateRanges.forEach(firstDateRange => {
                secondDateRanges.forEach(secondDateRange => {
                    if (firstDateRange.start.isSameOrBefore(secondDateRange.end) &&
                        firstDateRange.end.isSameOrAfter(secondDateRange.start)) {
                        isOverlap = true;
                    }
                });
            });

            return isOverlap;
        },
    }
}
</script>

<style scoped>

</style>